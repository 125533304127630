export const campaign_form = [
    {
        label: "Name*",
        name: "campaignName",
        type: "text-input",
        fieldProps: {
            defaultValue: "",
        },
    },
    {
        label: "Select Template*",
        name: "template",
        type: "select-picker",
        fieldProps: {
            isMulti: false,
            initialOptions: [
                {
                    label: "template1",
                    value: "template1",
                },
                {
                    label: "template2",
                    value: "template2",
                },
                {
                    label: "template3",
                    value: "template3",
                },
                {
                    label: "template4",
                    value: "template4",
                },
            ],
        },
    },
];

export const campaign_form_fields = {
    provenanceName: {
        validators: {
            notEmpty: {
                message: "Provenancce name is required",
            },
        },
    },
    template: {
        validators: {
            notEmpty: {
                message: "Template is required",
            },
        },
    },
};

export const campaign_create_form = [
    {
        type: "html",
        form: [
            {
                label: "Title*",
                name: "primaryText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Content*",
                name: "content",
                type: "ck-editor",
                fieldProps: {},
            },
            {
                label: "Upload Cover images*",
                name: "coverImage",
                type: "image-picker",
                tooltip: "Select images",
                fieldProps: {
                    fileType: "image",
                    noOfImages: 1,
                    docType: ["image/jpeg", "image/png", "image/jpg"],
                    buttonTitle: "Please Select Images",
                    // buttonColor: "#2664f5",
                    buttonColor: "transparent",
                    buttonContainerStyle: {},
                    allowMultipleSelect: true,
                    buttonTitleStyle: {
                        color: "#000000",
                    },
                    icon: {
                        name: "upload",
                        type: "font-awesome",
                        size: 20,
                        color: "#000000",
                    },
                },
            },
            {
                label: "Message*",
                name: "messageText",
                type: "text-area",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Text*",
                name: "buttonText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button URL*",
                name: "buttonUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Color",
                name: "buttonColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Title Color",
                name: "buttonTitleColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
        ],
    },
    {
        type: "video",
        form: [
            {
                label: "Title*",
                name: "primaryText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Video URL*",
                name: "videoUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Upload Cover images*",
                name: "coverImage",
                type: "image-picker",
                tooltip: "Select images",
                fieldProps: {
                    fileType: "image",
                    noOfImages: 1,
                    docType: ["image/jpeg", "image/png", "image/jpg"],
                    buttonTitle: "Please Select Images",
                    // buttonColor: "#2664f5",
                    buttonColor: "transparent",
                    buttonContainerStyle: {},
                    allowMultipleSelect: true,
                    buttonTitleStyle: {
                        color: "#000000",
                    },
                    icon: {
                        name: "upload",
                        type: "font-awesome",
                        size: 20,
                        color: "#000000",
                    },
                },
            },
            {
                label: "Message*",
                name: "messageText",
                type: "text-area",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Text*",
                name: "buttonText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button URL*",
                name: "buttonUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Color",
                name: "buttonColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Title Color",
                name: "buttonTitleColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
        ],
    },
    {
        type: "lottie",
        form: [
            {
                label: "Title*",
                name: "primaryText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Lottie Url*",
                name: "lottieUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Upload Cover images*",
                name: "coverImage",
                type: "image-picker",
                tooltip: "Select images",
                fieldProps: {
                    fileType: "image",
                    noOfImages: 1,
                    docType: ["image/jpeg", "image/png", "image/jpg"],
                    buttonTitle: "Please Select Images",
                    // buttonColor: "#2664f5",
                    buttonColor: "transparent",
                    buttonContainerStyle: {},
                    allowMultipleSelect: true,
                    buttonTitleStyle: {
                        color: "#000000",
                    },
                    icon: {
                        name: "upload",
                        type: "font-awesome",
                        size: 20,
                        color: "#000000",
                    },
                },
            },
            
            {
                label: "Message*",
                name: "messageText",
                type: "text-area",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Text*",
                name: "buttonText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button URL*",
                name: "buttonUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Color",
                name: "buttonColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Title Color",
                name: "buttonTitleColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
        ],
    },
    {
        type: "still-picture",
        form: [
            {
                label: "Title*",
                name: "primaryText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Upload image*",
                name: "stillImages",
                type: "image-picker",
                tooltip: "Select images",
                fieldProps: {
                    fileType: "image",
                    noOfImages: 5,
                    docType: ["image/jpeg", "image/png", "image/jpg"],
                    buttonTitle: "Please Select Images",
                    // buttonColor: "#2664f5",
                    buttonColor: "transparent",
                    buttonContainerStyle: {},
                    allowMultipleSelect: true,
                    buttonTitleStyle: {
                        color: "#000000",
                    },
                    icon: {
                        name: "upload",
                        type: "font-awesome",
                        size: 20,
                        color: "#000000",
                    },
                },
            },
            {
                label: "Upload Cover images*",
                name: "coverImage",
                type: "image-picker",
                tooltip: "Select images",
                fieldProps: {
                    fileType: "image",
                    noOfImages: 1,
                    docType: ["image/jpeg", "image/png", "image/jpg"],
                    buttonTitle: "Please Select Images",
                    // buttonColor: "#2664f5",
                    buttonColor: "transparent",
                    buttonContainerStyle: {},
                    allowMultipleSelect: true,
                    buttonTitleStyle: {
                        color: "#000000",
                    },
                    icon: {
                        name: "upload",
                        type: "font-awesome",
                        size: 20,
                        color: "#000000",
                    },
                },
            },
            {
                label: "Message*",
                name: "messageText",
                type: "text-area",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Text*",
                name: "buttonText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button URL*",
                name: "buttonUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Color",
                name: "buttonColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Title Color",
                name: "buttonTitleColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
        ],
    },
    {
        type: "voucher",
        form: [
            {
                label: "Title*",
                name: "primaryTitle",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Subtitle*",
                name: "secondaryTitle",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Upload images*",
                name: "voucherImage",
                type: "image-picker",
                tooltip: "Select images",
                fieldProps: {
                    fileType: "image",
                    noOfImages: 1,
                    docType: ["image/jpeg", "image/png", "image/jpg"],
                    buttonTitle: "Please Select Images",
                    // buttonColor: "#2664f5",
                    buttonColor: "transparent",
                    buttonContainerStyle: {},
                    allowMultipleSelect: true,
                    buttonTitleStyle: {
                        color: "#000000",
                    },
                    icon: {
                        name: "upload",
                        type: "font-awesome",
                        size: 20,
                        color: "#000000",
                    },
                },
            },
            {
                label: "Upload Cover images* ",
                name: "coverImage",
                type: "image-picker",
                tooltip: "Select images",
                fieldProps: {
                    fileType: "image",
                    noOfImages: 1,
                    docType: ["image/jpeg", "image/png", "image/jpg"],
                    buttonTitle: "Please Select Images",
                    // buttonColor: "#2664f5",
                    buttonColor: "transparent",
                    buttonContainerStyle: {},
                    allowMultipleSelect: true,
                    buttonTitleStyle: {
                        color: "#000000",
                    },
                    icon: {
                        name: "upload",
                        type: "font-awesome",
                        size: 20,
                        color: "#000000",
                    },
                },
            },
            {
                label: "Message*",
                name: "messageText",
                type: "text-area",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Text*",
                name: "buttonText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button URL*",
                name: "buttonUrl",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Color",
                name: "buttonColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Button Title Color",
                name: "buttonTitleColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Footer Text*",
                name: "footerText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Footer Sub Text*",
                name: "footerSubText",
                type: "text-input",
                fieldProps: {
                    defaultValue: "",
                },
            },
            {
                label: "Footer Background Color",
                name: "footerBackgroundColor",
                type: "color-picker",
                fieldProps: {
                    defaultValue: "",
                },
            },
        ],
    },
];

export const html_form_fields = {
    title: {
        validators: {
            notEmpty: {
                message: "Title is required",
            },
        },
    },
};

export const stillpicture_form_fields = {
    primaryText: {
        validators: {
            notEmpty: {
                message: "Title is required",
            },
            regexp: {
                regexp: "^(.{1,20})$",
                message: "Enter title with max 20 charaters"
            }
        },
    },
};

export const voucher_form_fields = {
    title: {
        validators: {
            notEmpty: {
                message: "Title is required",
            },
        },
    },
    coverImage: {
        validators: {
            notEmpty: {
                message: "Cotent is required",
            },
        },
    },
};
